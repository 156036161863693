import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

function About() {
    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <div className="content">
                        <h3>
                            Jake; a brief synopsis
                        </h3>
                        <img src={window.location.origin + '/images/me.jpg'} alt="Jacob Hershberger - Full Stack Developer" />
                        <p>
                            I have been designing, developing, and supporting enterprise applications since 2014. In March of 2018 I received a Certification in Full-Stack development from the University of Utah. When working on a project, I put myself into the mindset of the end user so I can provide the best results I can. I look into the different ways a user might use the app and then utilize my Graphic Design and Wed Development skills to develiver the best solution.
                            <br />
                            <br />
                            I love trying new things in development and I am not afraid of making mistakes because that is how we learn and ultimately improve ourselves.
                        </p>
                    </div>
                    <div className="content">
                        <h3>
                            Employment / Work History
                        </h3>
                        <img src={window.location.origin + '/images/primegov.png'} width="150" alt="Prime Government Solutions" />
                        <p>
                            <strong>Prime Government Solutions</strong><br />
                            Most recently I have been working at Prime Government Solutions located in Lehi, Utah. Prime Gov builds an agenda management software that is typically used by City Councils. Software functions include, but are not limited to meeting creation, live meeting streaming, public notifications, city committee management
                            <br />
                            <br />
                            <strong> Selected Contributions</strong>
                            <ul>
                                <li>
                                    Taking general specification enhancement documents and coded them into the existing software solutions.
						        </li>
                                <li>
                                    Building web forms for clients that submit agenda data and training co-workers on incorporating forms into the client workflows.
						        </li>
                                <li>
                                    Improved the implementation of translated material by standardizing the implementation method.
						        </li>
                            </ul>
                        </p>
                        <hr />
                        <img src={window.location.origin + '/images/Administrator_EDIT.png'} width="150" alt="Quomation Insurance Services" />

                        <p>
                            <strong>Quomation Insurance Services (Contractor)</strong><br />
				        	Quomation provides insurance agencies with various quoting/forms software to enable them to better serve their clients.
                            <br />
                            <br />
                            <br />
                            <strong> Selected Contributions</strong>
                            <ul>
                                <li>
                                    Initialized and oversaw the UI and UX of a new insurance forms project.
					        	</li>
                                <li>
                                    Built the client user management portion of the insurance forms project
					        	</li>
                                <li>
                                    Wrote style guides with reusable code snippets for other developers.
					        	</li>
                            </ul>
                        </p>
                        <hr />
                        <img src={window.location.origin + '/images/zija_pharamtech.png'} alt="Zija International" />
                        <p>
                            <strong>Pharmatech</strong><br />
					        I was with Pharmatech from early 2015 through mid-2018. Pharmatech provides back office solutions for network marketing companies of all sizes. These solutions include enrolling new distributors, allowing for placing of orders, along with checking commissions.
					        <br />
                            <br />
                            <br />
                            <strong> Selected Contributions</strong>
                            <ul>
                                <li>
                                    Creating mockups and coding out new application features with high fidelity to the original mockup.
					        	</li>
                                <li>
                                    Optimizing code to improve performance on user interfaces.
					        	</li>
                                <li>
                                    Closely worked with departments, executives, and customers to improve corporate software interfaces and online user interfaces
					        	</li>
                                <li>
                                    Worked on software utilized for corporate customer conventions.
					        	</li>
                                <li>
                                    A full rebuild of the account settings page, rendering the page 50% faster and greatly improving user friendliness.
					        	</li>
                                <li>
                                    Coded online customer interfaces to be 98% automatic language translatable.
					        	</li>
                            </ul>
                        </p>
                    </div>
                    <div className="content">
                        <h3>
                            Education
			        	</h3>
                        <img src={window.location.origin + '/images/university-of-utah_200x200.jpg'} alt="University of Utah" />

                        <p>
                            24-week long Bootcamp certification program dedicated to designing and building web applications. Skills covered consisted of HTML5, CSS3, Javascript, JQuery, Bootstrap, Firebase, Node Js, MySQL, MongoDB, Express, Handlebars JS, & React Js.
				    	<br />
                            <br />
					    I  received my Associates degree in Graphic Design from Stevens-Henager College in 2011.
					    <br />
                            <br />
					    In addition to the certification and the Graphic Design degree, I am constantly utilizing online tutorials to master new and exciting languages, among other things, within the tech industry.
				    </p>
                    </div>
                    <div className="content">
                        <h3>
                            A Final Note
			        	</h3>
                        <img className="acct_img" src="https://lh3.googleusercontent.com/SRo4tiZA9uGk1w9yg4hZ7mixWeayFqXCaS1nNXyIo4HOHERPbr6KSh7qkAbk6sFXLJbMW5im_m2Ye68ng4q6BM8XaNPWelDnuYETzguj0wbjx1RWKQPIfQqGkeSV2HA2qWSvaDBtHEaCcUpqWWeA3_6qPVCdF-ZS-9ovCJUiSejp9vJzYVRqNLFy7pLylzyES0OlrN9bD4fGO8xh6dIzR1JBpt1N2l0q4oSScGHu3GkmJDVbHsD7Nry7DpsyW2w49dFjI2QKV7ijLR5y31pBtrWYprNjAAM6MwuRd6rmQU1mJeNW9biF1C6dd0GCglNm7sNgExiNNltaD1240LVH85xn7LE6j70b581LCgWVl8HXleJkeY0wssm1ZQmFWzjDusZa2Rbx3065wt8RdN2Aj0-aJ9d1ZA3bbT8vuBtERDBS2SKegVqXDcxkXTfuhaoyNS63b7DEC-DjBQUOw1SwB25YLcQJ2A54yW3BY2uHpsbZgBcly1aEJ1_bFyyJ1dfdZkS97woU5hFuQTFXMIL3MCkEdzhR9ikBfyiEaYEQarpW9_EJ4Mge2GATu0JaI0cApBnbN22uqT4NHKnh5kbZVJAAk-ElMH13b5RleUckog=s400-no" alt="Web Developer in Dallas Texas" />

                        <p>
                            I come from a hard-working background and I always do whatever it takes to accomplish the task at hand. If I don't know how to do it, I figure it out. I work to complete a project before the end of the dedline, and I always provide a can-do attitude when any new project comes up.
					        <br />
                            <br />
                            <Link to="/Portfolio">Check Out My Portfolio</Link> or <Link to="/Contact">Get in Touch</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
} export default About;