import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Auth0Provider } from "@auth0/auth0-react";
import env from './Components/env'
// const onRedirectCallback = (appState) => {
//   history.push(
//     appState && appState.returnTo
//       ? appState.returnTo
//       : window.location.pathname
//   );
// };

if (process.env.NODE_ENV !== 'production') {
  console.log(env.environmentVariableDisplay())
  ReactDOM.render(
    <Auth0Provider
      domain="thehashbrown-us.us.auth0.com"
      clientId="Ry1iuNinOYIeQb0XaRJ6yMt71Wafmohb"
      redirectUri="http://localhost:3000/"
    >
      <App />
    </Auth0Provider>,
    document.getElementById('root')
  );

} else {
  console.log(env.environmentVariableDisplay())
  ReactDOM.render(
    <Auth0Provider
      domain="thehashbrown-us.us.auth0.com"
      clientId="Ry1iuNinOYIeQb0XaRJ6yMt71Wafmohb"
      redirectUri="https://www.jhwebdev.com/"
    >
      <App />
    </Auth0Provider>,
    document.getElementById('root')
  );

}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
