import React, { useEffect, useState } from 'react';
import 'reactjs-popup/dist/index.css';
import env from '../env'


function AdminPortfolio(props) {
    const [ProjectName, setProjectName] = useState("")
    const [ProjectDate, setProjectDate] = useState("")
    const [ProjectImage, setProjectImage] = useState("")
    const [ProjectDescription, setProjectDescription] = useState("")
    const [ProjectTech, setProjectTech] = useState("")
    const [ProjectVideo, setProjectVideo] = useState("")
    const [BeforeURL, setBeforeURL] = useState("")
    const [AfterURL, setAfterURL] = useState("")
    const [NormalURL, setNormalURL] = useState("")
    const [Count, setCount] = useState(0)
    const [projectId, setprojectId] = useState(0)

    /**
     * @function saveProject
     * @description Saves the values for the new project
     */
    function saveNewProject() {
        // console.log(ProjectName)
        let projectData = JSON.stringify({
            "Title": document.getElementById('ProjectName').value,
            "Description": document.getElementById('ProjectDescription').value,
            "ImageUrl": document.getElementById('ProjectImage').value,
            "BeforeWebUrl": document.getElementById('BeforeURL').value,
            "AfterWebUrl": document.getElementById('AfterURL').value,
            "Date": document.getElementById('ProjectDate').value,
            "TechUsed": document.getElementById('ProjectTech').value,
            "VideoUrl": document.getElementById('ProjectVideo').value,
            "WebUrl": document.getElementById('NormalURL').value,
            "UserId": props.userInfo.sub,
            "IsActive": 1
        })
        // console.log(projectData)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: projectData
        }
        fetch(`${env.baseUrl()}api/portfolio/projects`, requestOptions)
            .then(response => response.text())
            .then(result => {
                alert(result)
            })
            .catch(error => alert('error', error));

    }


    /**
     * @function valueChange
     * @description When adding a new portfolio item and the value changes, update the state.
     * @param {*} event 
     */
    function valueChange(event) {
        console.log('BOO')
        let inputName = event.target.name
        console.log(inputName)
        console.log(`${event.target.value} event.target.value`)
        console.log(`${ProjectName}`)
        switch (inputName) {
            case "ProjectName":
                setProjectName(event.target.value)
                break;
            case "ProjectDate":
                setProjectDate(event.target.value)
                break;
            case "ProjectImage":
                setProjectImage(event.target.value)
                break;
            case "ProjectDescription":
                setProjectDescription(event.target.value)
                break;
            case "ProjectTech":
                setProjectTech(event.target.value)
                break;
            case "ProjectVideo":
                setProjectVideo(event.target.value)
                break;
            case "BeforeURL":
                setBeforeURL(event.target.value)
                break;
            case "AfterURL":
                setAfterURL(event.target.value)
                break;
            case "NormalURL":
                setNormalURL(event.target.value)
                break;
        }
    }
    if (props.portfolioItem === 'new') {
        return (
            <div className="content">
                <h3>Add a New Portfolio Item</h3>
                <h5>What is the Project Name?</h5>
                <input className="form-control" name="ProjectName" id="ProjectName" placeholder="Super Awesome Page" onChange={valueChange} value={ProjectName} />
                <h5>What is the date of the project?</h5>
                <input type="date" className="form-control" name="ProjectDate" id="ProjectDate" value={ProjectDate} placeholder="mm/dd/yyyy" onChange={valueChange} />
                <h5>What is the project image?</h5>
                <input className="form-control" name="ProjectImage" id="ProjectImage" value={ProjectImage} placeholder="Super Awesome Image" onChange={valueChange} />
                <h5>What is the project Description?</h5>
                <input className="form-control" name="ProjectDescription" id="ProjectDescription" value={ProjectDescription} placeholder="Super Awesome Description" onChange={valueChange} />
                <h5>What is the Tech Used?</h5>
                <input className="form-control" name="ProjectTech" id="ProjectTech" value={ProjectTech} placeholder="Super Awesome Tech Used" onChange={valueChange} />
                <h5>Is there a video?</h5>
                <input className="form-control" name="ProjectVideo" id="ProjectVideo" value={ProjectVideo} placeholder="http://youtube.com/videoHere" onChange={valueChange} />
                <h5>Is there a Before URL?</h5>
                <input type="url" className="form-control" name="BeforeURL" id="BeforeURL" value={BeforeURL} placeholder="http://Before.url" onChange={valueChange} />
                <h5>Is there a After URL?</h5>
                <input type="url" className="form-control" name="AfterURL" id="AfterURL" value={AfterURL} placeholder="http://After.url" onChange={valueChange} />
                <h5>Is there a Website?</h5>
                <input type="url" className="form-control" name="NormalURL" id="NormalURL" value={NormalURL} placeholder="http://NormalURL.url" onChange={valueChange} />
                <button className="admin_functions text-success text-right btn" onClick={() => saveNewProject()}>Add New Project</button>
            </div>
        )
    } else {
        /**
         * @function saveUpdatedProject
         * @description Saves the values for the new project
         */
        function saveUpdatedProject() {
            // console.log(ProjectName)
            let projectData = JSON.stringify({
                "id": props.portfolioId,
                "Title": document.getElementById('ProjectName').value,
                "Description": document.getElementById('ProjectDescription').value,
                "ImageUrl": document.getElementById('ProjectImage').value,
                "BeforeWebUrl": document.getElementById('BeforeURL').value,
                "AfterWebUrl": document.getElementById('AfterURL').value,
                "Date": document.getElementById('ProjectDate').value,
                "TechUsed": document.getElementById('ProjectTech').value,
                "VideoUrl": document.getElementById('ProjectVideo').value,
                "WebUrl": document.getElementById('NormalURL').value,
                "UserId": props.userInfo.sub,
                "IsActive": 1
            })
            // console.log(projectData)
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            let requestOptions = {
                method: 'PATCH',
                headers: myHeaders,
                redirect: 'follow',
                body: projectData
            }
            fetch(`${env.baseUrl()}api/portfolio/project/${props.portfolioId}`, requestOptions)
                .then(response => response.text())
                .then(result => {
                    alert(result)
                })
                .catch(error => alert('error', error));

        }
        let x = ''
        if (Count == 0) {
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            }
            fetch(`${env.baseUrl()}api/portfolio/project/${props.portfolioId}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result)
                    for (let i in result) {
                        console.log(result[i].Title)
                        setProjectName(result[i].Title)
                       let  d = result[i].Date
                       d = d.split("T")
                        console.log(d[0]) 
                        setProjectDate(d[0])
                        setProjectImage(result[i].ImageUrl)
                        setProjectDescription(result[i].Description)
                        setProjectTech(result[i].TechUsed)
                        setProjectVideo(result[i].VideoUrl)
                        setBeforeURL(result[i].BeforeWebUrl)
                        setAfterURL(result[i].AfterWebUrl)
                        setNormalURL(result[i].WebUrl)
                        setprojectId(result[i].id)
                    }
                })
                .catch(error => console.log(error))
            setCount(Count + 1)
        }

        return (
            <div className="content">
                <h3>Edit {ProjectName} {props.portfolioId}</h3>
                <h5>What is the Project Name?</h5>
                <input className="form-control" name="ProjectName" id="ProjectName" placeholder="Super Awesome Page" onChange={valueChange} value={ProjectName} />
                <h5>What is the date of the project?</h5>
                <input className="form-control" name="ProjectDate" id="ProjectDate" value={ProjectDate} placeholder="mm/dd/yyyy" onChange={valueChange} />
                <h5>What is the project image?</h5>
                <input className="form-control" name="ProjectImage" id="ProjectImage" value={ProjectImage} placeholder="Super Awesome Image" onChange={valueChange} />
                <h5>What is the project Description?</h5>
                <input className="form-control" name="ProjectDescription" id="ProjectDescription" value={ProjectDescription} placeholder="Super Awesome Description" onChange={valueChange} />
                <h5>What is the Tech Used?</h5>
                <input className="form-control" name="ProjectTech" id="ProjectTech" value={ProjectTech} placeholder="Super Awesome Tech Used" onChange={valueChange} />
                <h5>Is there a video?</h5>
                <input className="form-control" name="ProjectVideo" id="ProjectVideo" value={ProjectVideo} placeholder="http://youtube.com/videoHere" onChange={valueChange} />
                <h5>Is there a Before URL?</h5>
                <input type="url" className="form-control" name="BeforeURL" id="BeforeURL" value={BeforeURL} placeholder="http://Before.url" onChange={valueChange} />
                <h5>Is there a After URL?</h5>
                <input type="url" className="form-control" name="AfterURL" id="AfterURL" value={AfterURL} placeholder="http://After.url" onChange={valueChange} />
                <h5>Is there a Website?</h5>
                <input type="url" className="form-control" name="NormalURL" id="NormalURL" value={NormalURL} placeholder="http://NormalURL.url" onChange={valueChange} />
                <button className="admin_functions text-success text-right btn" onClick={() => saveUpdatedProject(props.portfolioId)}>Save Changes</button>
            </div>
        )
    }

} export default AdminPortfolio