module.exports = {
    baseUrl: function () {
        let baseUrl = ''
        if (process.env.NODE_ENV !== 'production') {
            //dev
            baseUrl = 'http://localhost:5000/'
        } else {
            //prod
            baseUrl = 'https://api.decisionme.com/'
        }
        return baseUrl
    },
    environmentVariableDisplay: function() {
        let env = ''
        if (process.env.NODE_ENV !== 'production') {
            //dev
            env = 'You are in the DEVELOPMENT Environment'
        } else {
            //prod
            env = 'You are in the prod Environment'
        }
        return env
    }
}