import { useEffect, useState } from "react";
import JobDisplay from "../JobDisplay";

function Jobs() {
    const [JobHistory, setJobHistory] = useState("none")
    useEffect(() => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        }
        fetch("http://localhost:5000/api/portfolio/jobs", requestOptions)
            .then(response => response.json())
            .then(result => {
                
                let jobDisplay = <JobDisplay data={result} isAdmin={true}/>
                setJobHistory(jobDisplay)
            })
            .catch(error => console.log(error))
            
    })



    if (JobHistory != "none") {
        return (
            <span>
                {JobHistory}
            </span>
        )
    } else {
        return (
            <div>No jobs to display</div>
        )
    }

}
export default Jobs;


