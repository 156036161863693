import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
/**CSS */
import './App.css';

/**
 * Pages
 */
import About from './Pages/About'
import Portfolio from './Pages/Portfolio'
import Home from './Pages/Home';
import Admin from './Pages/Admin/Admin'

/**Components */
import Navigation from './Components/Navigation';
import Footer from './Components/Footer';

// import { useAuth0 } from "@auth0/auth0-react";
// import LoginButton from './components/Login';
// import LogoutButton from './components/Logout';

// Some folks find value in a centralized route config.
// A route config is just data. React is great at mapping
// data into components, and <Route> is a component.

// Our route config is just an array of logical "routes"
// with `path` and `component` props, ordered the same
// way you'd do inside a `<Switch>`.


export default function BasicExample() {
	// const { isAuthenticated } = useAuth0();
	// let LoginDisplay = ''
	// if (isAuthenticated) {
	// 	LoginDisplay = <LogoutButton />
	// } else {
	// 	LoginDisplay = <LoginButton />
	// }

	return (
		<Router>
			<Navigation />

			{/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
			<Switch>
				{/* <Route component={SingleRecipe} exact path="/Recipes/:id" />
				<Route component={Recipes} path="/Recipes" />
				<Route component={Dating} path="/Dating" /> */}
				<Route component={Admin} path="/Admin" />
				<Route component={About} path="/About" />
				<Route component={Portfolio} path="/Portfolio" />
				<Route component={Home} exact path="/" />
			</Switch>

			<Footer />

		</Router>
	);
}


// You can think of these components as "pages"
// in your app.

// function Main() {
	// const { user, isAuthenticated } = useAuth0();
	// let userDisplay = ''
	// if (isAuthenticated) {
	// 	userDisplay = <div>
	// 		<h2>
	// 			Welcome Back {user.nickname}!
	// 		</h2>
	// 		<p>I hope we can help you in your decision making needs</p>
	// 	</div>

	// } else {
	// 	userDisplay = <div>
	// 		<h2>
	// 			Home
	// 		</h2>
	// 	</div>
	// }

	// return (
	// 	<div className="App">
	// 		{/* <Navigation /> */}
	// 		<Home />
	// 		<Footer />
	// 	</div>

		// <div className="container-fluid">
		// 	{userDisplay}
		// </div>
// 	);
// }

