import { useState, useEffect } from 'react';

function JobDisplay(props) {
    const [CanEdit, setCanEdit] = useState(true)
    let display = "";

    function canEdit() {
        setCanEdit(true)
        console.log('what is up dipshit')
        console.log(CanEdit)
    }
    function dothis(event){
        console.log('doing what')
        console.log(event.target.value)
    }



    if (props.isAdmin) {

        display = <div className="content">
            {props.data.map((job) => (
                <span key={job.id}>

                    {/* ADD TO DB */}
                    <img src={window.location.origin + '/images/primegov.png'} width="150" alt={job.Company} />

                    <p>
                        <strong>
                            {job.Company} - {job.Title}
                            <input type="text" className="form-control" placeholder={job.Company} aria-label="Spaghetti and Meatballs" aria-describedby="button-addon2" onChange={dothis}/>

                        </strong>

                        <span className="admin_functions text-warning text-right" onClick={() => canEdit()}>Edit Job</span>
                        <span className="admin_functions text-danger text-right">Delete Job</span>
                        <br />
                        {job.Description}
                        <br />
                        <br />
                        {/* ADD TO DB */}
                        <strong> Selected Contributions</strong>

                        <ul>
                            <li>
                                Taking general specification enhancement documents and coded them into the existing software solutions.
                        </li>
                            <li>
                                Building web forms for clients that submit agenda data and training co-workers on incorporating forms into the client workflows.
                        </li>
                            <li>
                                Improved the implementation of translated material by standardizing the implementation method.
                        </li>
                        </ul>
                    </p>
                    <hr />

                </span>
            ))}
        </div>
    } else {
        display = <div className="content">
            {props.data.map((job) => (
                <span>
                    <img src={window.location.origin + '/images/primegov.png'} width="150" alt="Prime Government Solutions" />

                    <p key={job.id}>
                        <strong>{job.Company}</strong><br />
                    Most recently I have been working at Prime Government Solutions located in Lehi, Utah. Prime Gov builds an agenda management software that is typically used by City Councils. Software functions include, but are not limited to meeting creation, live meeting streaming, public notifications, city committee management
                    <br />
                        <br />

                        <strong> Selected Contributions</strong>

                        <ul>
                            <li>
                                Taking general specification enhancement documents and coded them into the existing software solutions.
                        </li>
                            <li>
                                Building web forms for clients that submit agenda data and training co-workers on incorporating forms into the client workflows.
                        </li>
                            <li>
                                Improved the implementation of translated material by standardizing the implementation method.
                        </li>
                        </ul>
                        <hr />
                    </p>
                </span>
            ))}
        </div>
    }
    return (
        <div> {display}</div>
    )
}
export default JobDisplay;


// {JobHistory.map((job) => (
//     <article key={job.id}  className="content">
//         <h3>
//             {job.Company} <br/>
//         </h3>
//         <strong>{job.Title}<br/>{job.StartDate} - {job.EndDate}</strong> 
//         {/* need to add */}
//         <img src={job.image} width="150"/>
//         <p>
//             {job.TechUsed}
//         </p>
//         <p>
//             {job.Description}
//         </p>
//         <strong>Selected Contributions</strong>
//         <p>
//             {/* need to add */}
//             {job.SelectedContributions}
//         </p>
//     </article>
// ))}