import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import env from '../Components/env';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

function Footer() {

	const { isAuthenticated } = useAuth0();
	const { logout } = useAuth0();
	const { loginWithRedirect } = useAuth0();

	let LoginDisplay = ''
	if (!isAuthenticated) {

		LoginDisplay = <button className="btn btn-link" onClick={() => loginWithRedirect()}>Log In</button>
	} else {
		LoginDisplay = <span>
			<button className="btn btn-link" onClick={() => logout()}>Log Out</button> | 
		 	<Link to="/Admin"><button className="btn btn-link">Admin Portal</button></Link>
			</span>
	}

	let isDevelopment
	if (process.env.NODE_ENV !== 'production') {
		isDevelopment = env.environmentVariableDisplay()
	}

	return (
		<footer id="footer" className="footer">
			<hr /> © <span id="cp-year"></span> Jacob Hershberger All Rights reserved. |{LoginDisplay} <br />
			{/* @if (SignInManager.IsSignedIn(User))
		{
                    <form asp-area="Identity" asp-page="/Account/Logout" asp-route-returnUrl="@Url.Action(" Index", "Home", new {area = ""})" method="post" id="logoutForm">
				<a asp-area="Identity" asp-page="/Account/Manage/Index" title="Manage" class="btn btn-link">Account</a>
                <button type="submit" class="btn btn-link  ">Logout</button>
			</form>
		}
		else
		{
			@*<a asp-area="Identity" asp-page="/Account/Register">Register</a>*@
			<br />
                <a asp-area="Identity" asp-page="/Account/Login">Login</a>
		} */}

		</footer>
	);
}

export default Footer;
