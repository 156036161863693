import { useEffect, useState } from "react";
import AddAdminPortfolioItem from "./AddAdminPortfolioItem"
import Popup from 'reactjs-popup';

import env from "../env"
function AdminPortfolio(props) {
    const [PortfolioItems, setPortfolioItems] = useState("none")
    const [value, setvalue] = useState(0)
    const [ProjectName, setProjectName] = useState("")
    const [ProjectDate, setProjectDate] = useState("")
    const [ProjectImage, setProjectImage] = useState("")
    const [ProjectDescription, setProjectDescription] = useState("")
    const [ProjectTech, setProjectTech] = useState("")
    const [ProjectVideo, setProjectVideo] = useState("")
    const [BeforeURL, setBeforeURL] = useState("")
    const [AfterURL, setAfterURL] = useState("")
    const [NormalURL, setNormalURL] = useState("")



    /**
     * ON LOAD
     * Request Portfolio items from the DB
     * @param value int that increments for each time this needs to load
     */
    useEffect(() => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        }
        fetch(`${env.baseUrl()}api/portfolio/projects`, requestOptions)
            .then(response => response.json())
            .then(result => setPortfolioItems(result))
            .catch(error => console.log(error))

    }, [value])

    /**
     * @function DeletePortfolioItem
     * @param {PortfolioId} PortfolioId 
     * @description Delete Portfolio Item
     */
    function DeletePortfolioItem(PortfolioId) {
        console.log(`${value} value`)
        console.log(PortfolioId)
        let requestOptions = {
            method: 'DELETE',
            redirect: 'follow'
        }
        fetch(`${env.baseUrl()}api/portfolio/project/${PortfolioId}`, requestOptions)
            .then(response => response.text())
            .then(result => alert(result))
            .catch(error => alert('error', error));
        // let newValue = JSON.stringify({value})
        // console.log(newValue)
        // newValue = parseInt(newValue)
        // newValue = newValue+1
        setvalue(value + 1)
    }


    if (PortfolioItems != "none") {
        return (
            <div className="col-sm-12">
                {PortfolioItems.map((portfolio) => (
                    <article key={portfolio.id} className="content">
                        <div className="col-sm-12">
                            <strong>{portfolio.Title}</strong><br />
                            <strong>Project Date - </strong> {portfolio.Date}
                            <Popup trigger={<button className="admin_functions text-success text-right btn">Edit Item</button>} modal>
                                <div><AddAdminPortfolioItem
                                    userInfo={props.userInfo}
                                    portfolioItem='edit'
                                    portfolioId={portfolio.id}
                                /></div>
                            </Popup>

                            <span className="admin_functions text-danger text-right btn" onClick={() => DeletePortfolioItem(portfolio.id)}>Delete Item {portfolio.id}</span>

                        </div>
                        {/* need to add */}
                        <div className="col-sm-12">
                            <img src={portfolio.ImageUrl} width="150" className="col-sm-12" />
                            <br />
                        </div>
                        <div className="col-sm-12">

                            <p>
                                <strong>Description</strong><br />
                                {portfolio.Description}

                            </p>
                            <p>
                                <strong>Tech Used</strong><br />
                                {portfolio.TechUsed}
                            </p>
                            <p>
                                <strong>Before and After</strong><br />
                                {portfolio.BeforeWebUrl} - {portfolio.AfterWebUrl}

                            </p>
                            <p>
                                <strong>Website</strong><br />
                                {portfolio.WebUrl}

                            </p>
                        </div>
                    </article>
                ))}
                {/* 
                    POPUP
                */}
                <Popup trigger={<button className="admin_functions text-success text-right btn">Add Portfolio Item</button>} modal>
                    <div><AddAdminPortfolioItem userInfo={props.userInfo} portfolioItem='new' /></div>
                </Popup>

            </div>
        )
    } else {
        return (
            <div>
                No portfolio items to display
                <Popup trigger={<button className="admin_functions text-success text-right btn">Add Portfolio Item</button>} modal>
                    <div><AddAdminPortfolioItem userInfo={props.userInfo} portfolioItem='new' /></div>
                </Popup>

            </div>
        )
    }

}
export default AdminPortfolio;


// "id": 1,
// "Title": "Zija International Login Page",
// "Description": "I did something really cool to the zija international login page!",
// "ImageUrl": "https://sp.mysqltutorial.org/wp-content/uploads/2017/02/MySQL-BOOLEAN-IF-function-example.png",
// "BeforeWebUrl": "https://web.archive.org/web/20160604104426/https://shield.zijasecure.com/login.html?language=EN",
// "AfterWebUrl": "https://web.archive.org/web/20170330130126/https://shield.zijasecure.com/login.html?language=",
// "WebUrl": "",
// "Date": "2017-05-01T05:00:00.000Z",
// "TechUsed": "HTML, JavaScript, CSS, SASS",
// "VideoUrl": "",
// "UserId": "auth0|5f1a850628ddba00374399d2",
// "IsActive": 1