import AdminPortfolio from "./AdminPortfolio"
import AdminJobs from "./AdminJobs"
import AdminEducation from "./AdminEducation";
import AddAdminPortfolioItem from "./AddAdminPortfolioItem"
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

function AdminLoggedIn(props) {
    function AddPortfolioItem() {
        console.log('add item')
    }

    return (
        <div className="row">
            <div className="col-sm-12">

                <div className="content">
                    <h3>
                        Admin Portal - Portfolio
                    </h3>
                    <AdminPortfolio userInfo={props.userInfo}/>


                </div>

                <div className="content">
                    <h3>
                        Admin Portal - Work History
                    </h3>
                    Use this section to add/remove jobs to your portfolio
                    <AdminJobs/>
                </div>

                <div className="content">
                    <h3>
                        Admin Portal - Education/Certifications
                    </h3>
                    Use this section to add/remove Education/Certifications to your portfolio
                    <AdminEducation/>
                </div>

                <div className="content">
                    <h3>
                        Admin Portal - Blog
                    </h3>
                    Use this section to add/remove jobs to your Blog
                </div>
                
            </div>
        </div>

    )
}
export default AdminLoggedIn;