import ViewPortfiolioBtn from '../Components/ViewPortfolioBtn'
function Home() {
    return (
        <div className="App">

            <div id="hero">
                <div
                    className="hero_img"
                    style={{
                        backgroundImage: `url('${window.location.origin}/images/dallas-skyline-5481023_1920.jpg')`
                    }}
                    // Should you change the image, make sure you change the css media queries as needed
                >
                </div>

            </div>

            <div className="hero_content container-fluid">
                <div className="">
                    <img className="hero_me" src={window.location.origin + '/images/me.jpg'} alt="Jake Hershberger, Full-Stack Developer in DFW (Dallas / Fort Worth) Texas" />
                    <br />
                    <p className="hero_me_desc">
                        Jake Hershberger
                        <br />
                        <span className='since'>Software Engineer | Certified Full-Stack Developer | Award Winning UI/UX Designer</span>
                    </p>
                    <br />
                    <p className="desc">
                        5+ years experience in the Designing, Developing, and Supporting Enterprise Applications.
                        <br />Utilizing Technologies such as Python, React, Javascript, C#, SQL, Mongo, Grafana, Prometheous, and more.
                        <br />Full-Stack Certification through The University of Utah (03/2018) with 2+ years dedicated experience.
                        <br />
                        <span className="since">Coding Since 2014</span>
                    </p>
                    <ViewPortfiolioBtn />
                </div>
            </div>
        </div>
    );
}

export default Home;
