import { useAuth0 } from "@auth0/auth0-react";
import AdminLoggedIn from "../../Components/Admin/AdminLoggedIn";
import Jobs from "../../Components/Admin/AdminJobs"
function Admin() {
    const { isAuthenticated, user } = useAuth0();
    let LoginDisplay = ''
    if (isAuthenticated && user.sub == 'auth0|5f1a850628ddba00374399d2') {
        LoginDisplay = <AdminLoggedIn userInfo={user} />

    } else {
        LoginDisplay = "To view this page, you need to log in."
    }

    return (
        <div className="container">
            {LoginDisplay}

        </div>
    )

}
export default Admin;