import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
// import WorkHistory from "../Components/WorkHistory";
// import Education from "../Components/Education";

function Navigation() {
	return (
		<nav id="nav" class="navbar navbar-default">

			<div class="navbar-header">
				<div class="nav-content">
					<Link to="/About" className="nav-item">About</Link>
					<Link to="/Portfolio" className="nav-item">Portfolio</Link>

					<Link to="">
						<img alt="brand_img" class=" brand_img" src={window.location.origin + '/images/jh-update.png'} width="80px;" />
					</Link>

					<Link to="/Contact" className="nav-item">Contact</Link>
					<Link to="" className="nav-item">Home</Link>
				</div>
			</div>
		</nav>



	);
}

export default Navigation;
