import { useEffect, useState } from "react";
import env from '../Components/env'

function Portfolio() {
    const [PortfolioItems, setPortfolioItems] = useState("none")
    const [value, setvalue] = useState(0)

    /**
     * ON LOAD
     * Request Portfolio items from the DB
     * @param value int that increments for each time this needs to load
     */
    useEffect(() => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        }
        fetch(`${env.baseUrl()}api/portfolio/projects`, requestOptions)
            .then(response => response.json())
            .then(result => setPortfolioItems(result))
            .catch(error => console.log(error))

    }, [value])

    if (PortfolioItems != "none") {
        return (
            <div className="col-sm-12">
                {PortfolioItems.map((portfolio) => (
                    <article key={portfolio.id} className="content">
                        <div className="col-sm-12">
                            <strong>{portfolio.Title}</strong><br />
                            <strong>Project Date - </strong> {portfolio.Date}

                        </div>
                        {/* need to add */}
                        <div className="col-sm-12">
                            <img src={portfolio.ImageUrl} width="150" className="col-sm-12" />
                            <br />
                        </div>
                        <div className="col-sm-12">

                            <p>
                                <strong>Description</strong><br />
                                {portfolio.Description}

                            </p>
                            <p>
                                <strong>Tech Used</strong><br />
                                {portfolio.TechUsed}
                            </p>
                            <p>
                                <strong>Before and After</strong><br />
                                {portfolio.BeforeWebUrl} - {portfolio.AfterWebUrl}

                            </p>
                            <p>
                                <strong>Website</strong><br />
                                {portfolio.WebUrl}

                            </p>
                        </div>
                    </article>
                ))}

            </div>
        )
    } else {
        return (
            <div>No portfolio items to display</div>
        )
    }
    // return (
    //     <div className="container">
    //         <div className="row">
    //             <section id="portfolio_item" className="col-sm-12">
    //                 {PortfolioItems.map((portfolio) => (
    //                     <article key={portfolio.id} className="content">
    //                         <div className="col-sm-12">
    //                             <strong>{portfolio.Title}</strong><br />
    //                             <strong>Project Date - </strong> {portfolio.Date}

    //                         </div>
    //                         {/* need to add */}
    //                         <div className="col-sm-12">
    //                             <img src={portfolio.ImageUrl} width="150" className="col-sm-12" />
    //                             <br />
    //                         </div>
    //                         <div className="col-sm-12">

    //                             <p>
    //                                 <strong>Description</strong><br />
    //                                 {portfolio.Description}

    //                             </p>
    //                             <p>
    //                                 <strong>Tech Used</strong><br />
    //                                 {portfolio.TechUsed}
    //                             </p>
    //                             <p>
    //                                 <strong>Before and After</strong><br />
    //                                 {portfolio.BeforeWebUrl} - {portfolio.AfterWebUrl}

    //                             </p>
    //                             <p>
    //                                 <strong>Website</strong><br />
    //                                 {portfolio.WebUrl}

    //                             </p>
    //                         </div>
    //                     </article>
    //                 ))}
    //                 {/* @foreach (var item in Model)
    //                 {
    //                     theProjectUrl2 = item.ProjectUrl2;
    //                     theProjectAccolade = item.ProjectAccolade;

    //                     <article class="content">
    //                         <h3>
    //                             @Html.DisplayFor(modelItem => item.ProjectName)
    //                         </h3>
    //                         @if (SignInManager.IsSignedIn(User))
    //                         {
    //                             @* If logged in display *@
    //                             <p>
    //                                 <a asp-action="Edit" asp-route-id="@item.ProjectId">Edit</a><span> | </span>
    //                                 <a asp-action="Details" asp-route-id="@item.ProjectId">Details</a><span> | </span>
    //                                 <a asp-action="Delete" asp-route-id="@item.ProjectId">Delete</a>
    //                             </p>
    //                         }

    //                         <img src="@Html.DisplayFor(modelItem => item.ProjectImage)" alt="@Html.DisplayFor(modelItem => item.ProjectName)">
    //                         <p>

    //                             @Html.DisplayFor(modelItem => item.ProjectDesc)
    //                             <br>

    //                             @if (theProjectAccolade != null)
    //                             {
    //                                 <br />
    //                                 <strong> @Html.DisplayFor(modelItem => item.ProjectAccolade) </strong>
    //                                 <br>
    //                             } 
    //                             <br />
    //                             <strong>MY ROLE:</strong>
    //                             @Html.DisplayFor(modelItem => item.ProjectRole)
    //                             <br><br>
    //                             <strong>SKILLS/TECH USED:</strong>
    //                             @Html.DisplayFor(modelItem => item.ProjectTech)
    //                             <br>
    //                             @if (theProjectUrl2 == null)
    //                             {
    //                                 @if (item.ProjectUrl1 == null)
    //                                 {
    //                                     //do not display
    //                                 }
    //                                 else
    //                                 {
    //                                     <a href="@Html.DisplayFor(modelItem => item.ProjectUrl1)" target="_blank">View Project</a>
    //                                 }
    //                             }
    //                             else
    //                             {
    //                                 <a href="@Html.DisplayFor(modelItem => item.ProjectUrl1)" target="_blank">View Before</a>

    //                                 <a href="@Html.DisplayFor(modelItem => item.ProjectUrl2)" target="_blank">View After</a>
    //                             }
    //                         </p>

    //                     </article>
    //                 } */}
    //             </section>
    //             <div className="pulse-btn" onclick="window.location.href='Home/About'">
    //                 <a href="about.html">More About Jake</a>
    //                 <div className="bg"></div>
    //             </div>
    //         </div>

    //     </div>
    // );
}

export default Portfolio;
